import React from 'react'
import Reveal from 'react-reveal/Reveal'
import Section from '../sections/Section'
import SectionTitle from '../components/sectionTitle'
import StandardPadding from '../components/standardPadding'
import RedButton from '../components/redButton'

import styles from '../styles/sections/numbered-section.module.scss'

const Item = ({ idx, title, subtext, paragraph, link, icon, numWhite }) => (
  <div className={styles.item}>
    <div className={styles.icon}>
      <span
        className={styles.num}
        style={{ color: numWhite && 'white' }}
      >{`0${idx + 1}`}</span>
      <Reveal effect="fadeInUpShorter">
        <img src={icon} />
      </Reveal>
    </div>
    {title && <h3>{title}</h3>}
    {subtext && <p className={styles.text}>{subtext}</p>}
    {paragraph && <p>{paragraph}</p>}
    {link && <RedButton to={link.to} text="See the Program" />}
  </div>
)

const NumberedSection = ({ title, label, items, background, spacing }) => {
  return (
    <Section background={background} spacing={spacing}>
      <StandardPadding>
        <SectionTitle title={title} label={label} />
        <div className={styles.container}>
          {items.map((item, i) => (
            <Item
              idx={i}
              numWhite={background}
              key={i}
              subtext={item.subtext}
              paragraph={item.paragraph}
              title={item.title}
              link={item.link}
              icon={item.icon}
            />
          ))}
        </div>
      </StandardPadding>
    </Section>
  )
}

export default NumberedSection
