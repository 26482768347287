import React from 'react'
import Reveal from 'react-reveal/Reveal'

import Section from '../sections/Section'
import SectionTitle from '../components/sectionTitle'
import StandardPadding from '../components/standardPadding'

import styles from '../styles/sections/text-section.module.scss'

import classNames from 'classnames/bind'
let cx = classNames.bind(styles)

const TextSection = ({
  title,
  titleColor,
  label,
  image,
  spacing,
  background,
  paragraph,
  info,
  center,
}) => {
  return (
    <Section spacing={spacing} background={background}>
      <StandardPadding>
        <SectionTitle
          title={title}
          label={label}
          titleColor={titleColor}
          center={center}
        />
        <Reveal effect="fadeInUpShorter">
          <div className={cx('textContainer', { center })}>
            <p>{paragraph}</p>
            {info && <p className={styles.info}>{info}</p>}
          </div>
        </Reveal>
        {image && (
          <Reveal effect="fadeInUpShorter">
            <div className={styles.imageContainer}>
              <img src={image} />
            </div>
          </Reveal>
        )}
      </StandardPadding>
    </Section>
  )
}

export default TextSection
