import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/SEO'
import Hero from '../components/hero'
import WhatTheFinch from '../components/whatTheFinch'
import FeaturedProjectsCarousel from '../components/featuredProjectsCarousel'
import EmailCollect from '../components/emailCollect'
import Footer from '../components/footer'

import NumberedSection from '../sections/NumberedSection'
import TextSection from '../sections/TextSection'
import HighlightedTextSection from '../sections/HighlightedTextSection'
import IconGridSection from '../sections/IconGridSection'

import styles from '../styles/pages/about-styles.module.scss'
import adaptabilityGraph from '../images/adaptability-graph.jpg'
import lightbulb from '../images/icons/lightbulb.png'
import pencil from '../images/icons/pencil.png'
import briefcase from '../images/icons/briefcase.png'
import paper from '../images/icons/paper.png'
import wireframe from '../images/icons/wireframe.png'
import browser from '../images/icons/browser.png'
import laptop from '../images/icons/laptop.png'
import box from '../images/icons/box.png'
import projectRelationship from '../images/icons/project-relationship.png'
import ongoingRelationship from '../images/icons/ongoing-relationship.png'
import partnershipProgram from '../images/icons/partnership-program.png'

const AboutPage = ({ data }) => {
  const sections = data.contentfulPage.sections
  return (
    <Layout>
      <SEO customTitle="About" pagePath="about" />
      <Hero {...sections[0]} />
      <HighlightedTextSection
        title="We provide an array of services and support over time, adjusting to your company's needs to help you adapt and thrive."
        label="Ways we can help"
        spacing="small"
      />
      <IconGridSection
        label="Our Services"
        title="Services that help you drive growth"
        icons={[
          { icon: lightbulb, title: 'Brand Discovery & Strategy' },
          { icon: pencil, title: 'Brand Identity' },
          { icon: briefcase, title: 'Marketing Strategy & Planning' },
          { icon: paper, title: 'Marketing Materials & Activation' },
          { icon: wireframe, title: 'UI/UX Design' },
          { icon: browser, title: 'Front End Development' },
          { icon: laptop, title: 'Back End & Software Engineering' },
          { icon: box, title: 'Product Strategy & Development' },
        ]}
      />
      <NumberedSection
        title="How we work with startups"
        label="Startup + 15 Finches Relationships"
        background="peach"
        spacing="small"
        items={[
          {
            title: 'Project Based Relationship',
            paragraph:
              'This is the typical agency-client relationship. Everyone knows what to expect - project cost, timeline and scope is set. Works best for one-off projects. This model typically includes cash payments.',
            icon: projectRelationship,
          },
          {
            title: 'Ongoing Relationship',
            paragraph:
              'For startups who have changing needs over time and would benefit from a long-term, adaptable, incentivized partner. This model usually includes a cash retainer (only charge operating expenses) and a small piece of equity.',
            icon: ongoingRelationship,
          },
          {
            title: 'Partnership Program',
            paragraph:
              'Access to mentorship, founder and VC intros, transformational workshops and an array of creative, development and product services during the three month program. Startups give between 1-5% equity and operating expenses paid for services provided.',
            icon: partnershipProgram,
            link: { to: '/partnership-program/', text: 'See the Program' },
          },
        ]}
      />
      <TextSection
        title="We provide the support and services you need when you need it"
        label="Our Adaptability"
        paragraph="Don’t spend days looking for the right partner for each individual task that your team isn’t equipped to handle. Our team is built to adjust to your changing needs.  Below is a sample graph showing the change in services over time."
        image={adaptabilityGraph}
        center
        spacing="small"
      />
      <FeaturedProjectsCarousel {...sections[1]} />
      <TextSection
        title="We’re a part of the startup ecosystem"
        titleColor="red"
        label="Our Community"
        paragraph="By establishing meaningful relaionships with VCs, we’re working to keep our place within the startup ecosystem connected and fruitful. Having worked with many startups and VCs in the past, we’ve found that having VCs that trust us is the most beneficial relationship because it is built on alignment and the harmonized goals of helping startups adapt and thrive."
        center
      />
      <WhatTheFinch {...sections[2]} />
      <EmailCollect />
      <Footer isFullHeight={false} />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    contentfulPage(title: { eq: "About" }) {
      sections {
        ... on ContentfulHero {
          __typename
          header
          headerHighlightedText
          headerSize
          subheader
        }
        #  ... on ContentfulThreePoints {
        #    __typename
        #    sections {
        #      ... on ContentfulTextIconGroup {
        #        __typename
        #        icon {
        #          fixed(width: 150, height: 150) {
        #            ...GatsbyContentfulFixed_withWebp_noBase64
        #          }
        #        }
        #        text {
        #          childMarkdownRemark {
        #            html
        #          }
        #        }
        #      }
        #    }
        #    bottomImage {
        #      file {
        #        url
        #      }
        #      fluid(maxWidth: 1280, quality: 80) {
        #        ...GatsbyContentfulFluid_withWebp
        #      }
        #    }
        #  }
        #  ... on ContentfulOurServices {
        #    __typename
        #    sectionHeader
        #    subsections {
        #      __typename
        #      ... on ContentfulHeaderTextBody {
        #        header
        #        body {
        #          body
        #        }
        #      }
        #    }
        #  }
        ... on ContentfulWhatTheFinch {
          __typename
          title
          bodyText {
            bodyText
          }
          backgroundImage {
            fluid(maxWidth: 1208, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          finchHeadIcon {
            file {
              url
            }
          }
        }
        ... on ContentfulFeaturedProjectsCarousel {
          __typename
          id
          featuredCaseStudies {
            ... on ContentfulCaseStudy {
              title
              slug
              projectStatement
              projectStatementHighlighted
              thumbnailImage {
                fluid(maxWidth: 474, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              sketch {
                fluid(maxWidth: 375) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
