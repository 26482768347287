import React from 'react'
import Reveal from 'react-reveal/Reveal'

import Section from './Section'
import SectionTitle from '../components/sectionTitle'
import StandardPadding from '../components/standardPadding'

import styles from '../styles/sections/icon-grid-section.module.scss'

import classNames from 'classnames/bind'
let cx = classNames.bind(styles)

const IconGridSection = ({ title, label, icons }) => {
  return (
    <Section spacing="large">
      <StandardPadding>
        <SectionTitle title={title} label={label} />
        <div className={styles.grid}>
          {icons.map(icon => {
            return (
              <div className={styles.icon}>
                <div className={styles.circle}>
                  <Reveal effect="fadeInUpShorter">
                    <img src={icon.icon} alt="" />
                  </Reveal>
                </div>
                <p>{icon.title}</p>
              </div>
            )
          })}
        </div>
      </StandardPadding>
    </Section>
  )
}

export default IconGridSection
