import React from 'react'
import { Link } from 'gatsby'
import styles from '../styles/components/red-button.module.scss'

import RightArrowWhite from '../images/right-arrow-right.svg'

const RedButton = ({ text, style, to, onClick, type }) => {
  if (to) {
    return (
      <Link to={to} className={styles.button} style={style} data-hover="expand">
        {text}
        <RightArrowWhite />
      </Link>
    )
  }
	return (
		<button type="submit" className={styles.button} style={style} data-hover="expand-white">
			{text}
			<RightArrowWhite />
		</button>
	)
}

export default RedButton
